import Link from "next/link";

const BreadCrumb = ({ title, text }) => {
  return (
    <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 pt_sm--100 pb_sm--50 bg-gradient-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner text-center">
              <h2 className="title">{title}</h2>
              {(title !== 'Admissions') ? <ul className="page-list">
                <li className="rbt-breadcrumb-item">
                  <Link href="/">Home</Link>
                </li>
                <li>
                  <div className="icon-right">
                    <i className="feather-chevron-right"></i>
                  </div>
                </li>
                <li className="rbt-breadcrumb-item active">{text}</li>
              </ul> : <p>At GD College, we understand the importance of providing our students with the guidance and support they need to excel in their academic and career pursuits in the cosmetology industry. Our academic counselling program is designed to help students identify the right program for their individual passions and lifestyle, and our career counselling program is designed to help students and alumni navigate the complex world of career advancement in the industry.</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
